import React from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';

import './Navigation.scss';

const Navigation = () => {
  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          allPagesJson(sort: {fields: [order], order: ASC}) {
            nodes {
              title
              URI
              order
            }
          }
          allSettingsJson {
            nodes {
              banner {
                twitter
                youtube
                instagram
              }
            }
          }
        }
      `}
      render={data => (
        <nav className="nav">
          <ul>
            {data.allPagesJson.nodes.map(menuItem => (
              <li key={menuItem.title}>
                <Link to={menuItem.URI}>
                  { menuItem.title }
                </Link>
              </li>
            ))}
          </ul>
          <ul className="nav-social">
            {data.allSettingsJson.nodes[0].banner.youtube && (
              <li>
                <a target='_blank' href={data.allSettingsJson.nodes[0].banner.youtube}>
                  YouTube
                  <i className="material-icons-rounded">east</i>
                </a>
              </li>
            )}
            {data.allSettingsJson.nodes[0].banner.twitter && (
              <li>
                <a target='_blank' href={data.allSettingsJson.nodes[0].banner.twitter}>
                  Twitter
                  <i className="material-icons-rounded">east</i>
                </a>
              </li>
            )}
            {data.allSettingsJson.nodes[0].banner.instagram && (
              <li>
                <a target='_blank' href={data.allSettingsJson.nodes[0].banner.instagram}>
                  Instagram
                  <i className="material-icons-rounded">east</i>
                </a>
              </li>
            )}
          </ul>
        </nav>
      )}
    />
  )
};

export default Navigation;