import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import Cart from '../Cart/Cart';
import Navigation from '../Navigation/Navigation';

import './Header.scss';

const Header = ({ menuOpen, setMenuOpen, cart }) => {
  const toggleMenu = () => {
    const body = document.querySelector('body');
    body.classList.add('no-scroll');
    setMenuOpen(!menuOpen);
  }

  return (
    <header className="header">
      <button className={classNames(`button button--menu`, {'button--open': menuOpen})} onClick={ () => toggleMenu() }>
        <i className="material-icons-rounded">{ menuOpen ? 'close' : 'menu' }</i>
      </button>
      { menuOpen &&
        <Navigation />
      }
      <Link to="/" className="logo">
        Wibi Soerjadi
      </Link>
      <div className="header-right">
        { cart &&
          <Cart />
        }
      </div>
    </header>
  )
}

Header.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  cart: PropTypes.bool.isRequired
};

export default Header;
