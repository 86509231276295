import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import './Banner.scss';

const Banner = () => {
  return (
    <StaticQuery
      query={graphql`
        query SettingsQuery {
          allSettingsJson {
            nodes {
            banner {
              content
            }
          }
        }
      }
    `}
      render={data => (
        <div className="banner">
          <ReactMarkdown children={data.allSettingsJson.nodes[0].banner.content} />
        </div>
      )}
    />
  )
};

export default Banner;