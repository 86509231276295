import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Header from '../Header/Header';
import Banner from '../Banner/Banner';
import ProductsProvider from '../Products/ProductsProvider';
import CartProvider from '../Cart/CartProvider';

import '../../scss/App.scss';
import './StoreLayout.scss';

const StoreLayout = ({ children, page, poster }) => {
  const app = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = () => {
      const body = document.querySelector('body');

      if (isMenuOpen && app.current) {
        body.classList.remove('no-scroll');
        setIsMenuOpen(false);
      }
    }

    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('click', checkIfClickedOutside);
    }
  }, [isMenuOpen])

  return (
    <ProductsProvider>
      <CartProvider>
        <div id="app" className={page && `page ${page}`} ref={app}>
          <Banner />
          <Header cart menuOpen={isMenuOpen} setMenuOpen={setIsMenuOpen} />
          { !poster
              ? <div className={`hero hero--${ page }`} />
              : <div className={`hero hero--${ page }`} style={{
                backgroundImage: `url(${poster.image})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
              }} /> }
          {children}
        </div>
      </CartProvider>
    </ProductsProvider>
  )
}

StoreLayout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string,
};

export default StoreLayout;
